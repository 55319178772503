<template>
  <header v-if="currentRouteName === 'home'">
    <div class="flex justify-center">
      <router-link to="/"
        ><img
          class="sm:ml-5 w-48 my-6 sm:m-0 sm:w-40 lg:w-48"
          src="@/assets/images/logo.png"
      /></router-link>
    </div>
    <div v-if="remaining > 0" class="m-auto mb-6 sm:m-0 hidden md:block">
      <router-link
        to="/before"
        class="sm:mr-10 text-[#0C6B9D] font-medium text-lg"
        >ACCÉDER AU FORMULAIRE</router-link
      >
    </div>
  </header>
</template>

<
<style lang="scss">
header {
  @apply w-full text-center sm:flex sm:flex-wrap sm:justify-between sm:items-center sm:h-24 max-w-[1920px] m-auto;
}
</style>
<script>
import axios from "axios";
export default {
  name: "Header",
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data() {
    return {
      remaining: 0,
    };
  },
  async mounted() {
    let { data } = await this.getCounter();
    let number = 0;

    if (data && data.length > 0) {
      number = data[0].count;
    }

    let remaining = 2169 - Number(number);
    this.remaining = remaining;
  },
  methods: {
    getCounter() {
      return axios.get(
        "https://api.lucien.ai/api/operations-custom/atlantic-takao-2023/stats"
      );
    },
  },
};
</script>
